import {
    ColumnDef,
    Header,
    HeaderGroup,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    PlusIcon,
} from "@heroicons/react/24/outline";
import { BaseTextSearchFilter } from "./TableFilters";
import { PrimaryButton } from "../../ui/Buttons";
import { filterStateToApiParams, tableStateFamily } from "./state";
import { useAtom } from "jotai";
import { camelToSnakeCase } from "../../utils/string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faSort } from "@fortawesome/pro-light-svg-icons";
import { faSort as faSortSolid } from "@fortawesome/pro-solid-svg-icons";

const DEFAULT_PAGE_SIZES = [5, 10, 20, 50];

interface TableFilterSortProps {
    dataName: string;
    header: Header<any, any>;
}

const TableFilter = (props: TableFilterSortProps) => {
    // Extract columnDef and filter definition parameters
    const columnDef = props.header.column.columnDef;
    const filterKey = columnDef.meta?.filteringKey || columnDef.id;

    // Retrieve table state and filter values
    const [tableState, setTableState] = useAtom(
        tableStateFamily({ tableId: props.dataName }),
    );
    const value = tableState.filters[filterKey];
    const setValue = useCallback((newValue: any) => {
        setTableState((ts) => {
            return {
                ...ts,
                filters: {
                    ...ts.filters,
                    [filterKey]: newValue,
                },
            };
        });
    }, []);

    if (columnDef.meta && columnDef.meta.customFilterWidget) {
        return (
            <columnDef.meta.customFilterWidget
                isFiltered={!!value}
                filterValue={value}
                setFilterValue={setValue}
            />
        );
    }

    return (
        <BaseTextSearchFilter
            isFiltered={!!value}
            filterValue={value as string}
            setFilterValue={setValue}
        />
    );
};

const TableSort = (props: TableFilterSortProps) => {
    // Extract columnDef and filter definition parameters
    const columnDef = props.header.column.columnDef;
    const sortKey =
        columnDef.meta?.sortingKey || camelToSnakeCase(columnDef.id);

    // Retrieve table state and filter values
    const [tableState, setTableState] = useAtom(
        tableStateFamily({ tableId: props.dataName }),
    );
    const isSorted = tableState.sorting && tableState.sorting.id === sortKey;
    const setSorting = useCallback(() => {
        setTableState((ts) => {
            return {
                ...ts,
                sorting: {
                    id: sortKey,
                    desc:
                        ts.sorting && ts.sorting.id === sortKey
                            ? !ts.sorting.desc
                            : false,
                },
            };
        });
    }, []);

    return (
        <button onClick={setSorting}>
            <FontAwesomeIcon
                icon={isSorted ? faSortSolid : faSort}
                className={`w-3 ${isSorted ? "" : "text-gray-400"}`}
            />
        </button>
    );
};

interface TableHeaderProps<T> {
    dataName: string;
    headers: HeaderGroup<T>[];
}

const TableHeader = <T,>(props: TableHeaderProps<T>) => (
    <thead className="bg-ae-gray-100 text-left text-sm font-bold text-ae-blue-900 border-b">
        {props.headers.map((headerGroup) => (
            <>
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                        <th
                            key={header.id}
                            className="px-4 py-3 hover:bg-neutral-200 border-r border-gray-200 last:border-r-0"
                            style={{
                                width:
                                    header.getSize() === Number.MAX_SAFE_INTEGER
                                        ? "auto"
                                        : header.getSize(),
                            }}
                        >
                            {header.isPlaceholder ? null : (
                                <span className="flex items-center justify-between whitespace-nowrap">
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )}
                                    <div className="ml-3 flex items-center gap-1">
                                        {header.column.getCanSort() && (
                                            <TableSort
                                                dataName={props.dataName}
                                                header={header}
                                            />
                                        )}
                                        {header.column.getCanFilter() && (
                                            <TableFilter
                                                dataName={props.dataName}
                                                header={header}
                                            />
                                        )}
                                    </div>
                                </span>
                            )}
                        </th>
                    ))}
                </tr>
            </>
        ))}
    </thead>
);

interface TableFooterProps {
    loadingData: boolean;
    currentPage: number;
    pageCount: number;
    itemCount: number;
    nextPage: () => void;
    nextPageEnabled: boolean;
    previousPage: () => void;
    previousPageEnabled: boolean;
    defaultPageSize: number;
    selectedPageSize: number;
    onPageChange?: (page: number) => void;
    onSelectPageSize: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const TableFooter = (props: TableFooterProps) => {
    const getPageNumbers = () => {
        const pageNumbers = [];
        const currentPage = props.currentPage;
        const totalPages = props.pageCount;

        if (totalPages <= 6) {
            // Show all pages if we only have up to 6 pages
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            // Always show first page
            pageNumbers.push(1);

            // Adjust ranges based on current page position
            if (currentPage <= 4) {
                pageNumbers.push(...Array.from({ length: 4 }, (_, i) => i + 2));
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            } else if (currentPage > totalPages - 4) {
                // When close to end, show last 5 pages
                pageNumbers.push("...");
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pageNumbers.push(i);
                }
            } else {
                // In middle, show current page and neighbors
                pageNumbers.push("...");
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            }
        }

        return pageNumbers;
    };

    return (
        <div className="rounded-b bg-white border-slate-200 flex items-center justify-between w-full text-sm font-medium p-3 text-ae-blue-900">
            <div>{props.itemCount} Items</div>

            {props.loadingData && (
                <div className="flex items-center gap-2">
                    Fetching data...
                    <FontAwesomeIcon
                        icon={faArrowsRotate}
                        className="w-4 animate-spin"
                    />
                </div>
            )}

            <div className="flex items-center">
                <button
                    className={`mr-2 p-1 rounded-md ${
                        props.previousPageEnabled
                            ? "border border-transparent hover:border-ae-blue-550 hover:text-ae-blue-550"
                            : "text-neutral-400"
                    }`}
                    onClick={props.previousPage}
                    disabled={!props.previousPageEnabled}
                >
                    <ChevronLeftIcon className="h-5" />
                </button>

                <div className="flex gap-1">
                    {getPageNumbers().map((pageNum, index) =>
                        pageNum === "..." ? (
                            <button
                                key={`ellipsis-${index}`}
                                className="px-2 py-1 rounded-md border border-transparent hover:border-ae-blue-550 hover:text-ae-blue-550"
                                onClick={() => {
                                    const page = prompt("Enter page number:");
                                    const pageNumber = parseInt(page || "");
                                    if (
                                        pageNumber &&
                                        pageNumber > 0 &&
                                        pageNumber <= props.pageCount
                                    ) {
                                        props.onPageChange?.(pageNumber);
                                    }
                                }}
                            >
                                ...
                            </button>
                        ) : (
                            <button
                                key={pageNum}
                                className={`px-3 py-1 rounded-md border ${
                                    pageNum === props.currentPage
                                        ? "border-ae-blue-550 text-ae-blue-550"
                                        : "border-transparent hover:border-ae-blue-550 hover:text-ae-blue-550"
                                }`}
                                onClick={() => props.onPageChange?.(pageNum)}
                            >
                                {pageNum}
                            </button>
                        ),
                    )}
                </div>
                <button
                    className={`p-1 rounded-md ${
                        props.nextPageEnabled
                            ? "border border-transparent hover:border-ae-blue-550 hover:text-ae-blue-550"
                            : "text-neutral-400"
                    }`}
                    onClick={props.nextPage}
                    disabled={!props.nextPageEnabled}
                >
                    <ChevronRightIcon className="h-5" />
                </button>
                <select
                    value={props.selectedPageSize}
                    onChange={props.onSelectPageSize}
                    className="ml-4 h-7 text-sm rounded border-neutral-300 py-1"
                >
                    {DEFAULT_PAGE_SIZES.map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                    {props.defaultPageSize &&
                        !DEFAULT_PAGE_SIZES.includes(props.defaultPageSize) && (
                            <option
                                key={props.defaultPageSize}
                                value={props.defaultPageSize}
                            >
                                {props.defaultPageSize}
                            </option>
                        )}
                </select>
            </div>
        </div>
    );
};

interface RowActionButtonsProps {
    actions: {
        fn: () => void;
        icon: ReactNode;
        tooltip?: string;
    }[];
}

export const RowActionButtons = (props: RowActionButtonsProps) => {
    return (
        <div className="w-full flex items-center justify-end gap-1 -my-2">
            {props.actions.map((action, index) => {
                return (
                    <div
                        key={index}
                        className="group relative flex items-center"
                    >
                        <button
                            onClick={action.fn}
                            className="h-9 w-9 flex items-center justify-center text-ae-blue-900 p-1 rounded hover:border-2 border-ae-gray-300"
                        >
                            {action.icon}
                        </button>
                        {action.tooltip && (
                            <span className="z-0 absolute right-full px-3 py-1 mr-3 text-sm bg-white border border-white rounded drop-shadow-md opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap">
                                {action.tooltip}
                                <svg
                                    className="absolute top-1/2 -right-2 h-2 w-2 fill-current text-white transform -translate-y-1/2"
                                    viewBox="0 0 20 20"
                                >
                                    <polygon points="0,0 20,10 0,20" />
                                </svg>
                            </span>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

interface DataTableV3Props<T> {
    // TODO: improve typing
    dataName: string;
    columns: ColumnDef<T, any>[];
    fetchFunction: any;
    sortable: boolean;
    filterable: boolean;
    extraFilters: {
        [key: string]: any;
    };
    onClickRow?: (string) => void;
    defaultPageSize?: number;
    partialUpdate?: (id: string, value: Partial<T>) => Promise<void>;
    deleteRow?: (id: string) => Promise<void>;
    createRow?: () => void;
    getRowId?: (row: T) => any;
}

/**
 * New data table, using atoms for state management.
 */
export const DataTableV3 = <T,>(props: DataTableV3Props<T>) => {
    const defaultData = useMemo(() => [], []);
    const { extraFilters } = props;
    const [tableState, setTableState] = useAtom(
        tableStateFamily({
            tableId: props.dataName,
        }),
    );

    const getRowId = props.getRowId ?? ((row: any) => row.id);

    /**
     * Query builder & fetch/update functions
     */
    const fetchDataOptions = useMemo(() => {
        return {
            ...extraFilters,
            ...filterStateToApiParams(tableState.filters),
            ordering: tableState.sorting
                ? `${tableState.sorting.desc ? "-" : ""}${
                      tableState.sorting.id
                  }`
                : undefined,
            page: tableState.pagination.pageIndex + 1,
            pageSize: tableState.pagination.pageSize,
        };
    }, [tableState, extraFilters]);

    const queryClient = useQueryClient();
    const dataQuery = useQuery(
        [props.dataName, fetchDataOptions],
        () => props.fetchFunction(fetchDataOptions),
        {
            keepPreviousData: true,
            retry: 1,
        },
    );

    // Handle when pages don't exist, resetting the pagination
    // to 0 when the invalid page error is found.
    useEffect(() => {
        const handleError = async () => {
            const response = await (dataQuery.error as any).response.json();
            if (response.detail === "Invalid page.") {
                setTableState((ts) => {
                    return {
                        ...ts,
                        pagination: {
                            ...ts.pagination,
                            pageIndex: 0,
                        },
                    };
                });
            }
        };
        if (dataQuery.isError) {
            handleError();
        }
    }, [dataQuery]);

    // TODO: Add types
    const partialUpdate = useMutation<any, any, any, any>(
        ["partialUpdate"],
        async (params) => {
            return props.partialUpdate(params.itemId, params.value);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: [props.dataName, fetchDataOptions],
                });
            },
        },
    );

    // TODO: Add types
    const deleteRow = useMutation<any, any, any, any>(
        ["deleteRow"],
        async (params) => {
            return props.deleteRow(params.itemId);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: [props.dataName, fetchDataOptions],
                });
            },
        },
    );

    /**
     * Table configuration
     */
    const table = useReactTable({
        data: dataQuery.data?.results ?? defaultData,
        columns: props.columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (originalRow: any) => `row_${getRowId(originalRow)}`,
        defaultColumn: {
            minSize: 0,
            size: Number.MAX_SAFE_INTEGER,
            maxSize: Number.MAX_SAFE_INTEGER,
        },
        // Pagination
        pageCount: dataQuery.data?.numPages,
        onPaginationChange: (updater: any) =>
            setTableState((ts) => {
                return {
                    ...ts,
                    pagination: updater(ts.pagination),
                };
            }),
        manualPagination: true,
        state: {
            pagination: tableState.pagination,
            columnVisibility: tableState.columnVisibility,
        },
        // Update row support
        meta: {
            updateData: (rowIndex, itemId, value) => {
                return partialUpdate.mutateAsync({ rowIndex, itemId, value });
            },
            deleteRow: (rowIndex, itemId) => {
                return deleteRow.mutateAsync({ rowIndex, itemId });
            },
            refresh: async () => {
                dataQuery.refetch();
            },
        },
        // Sorting
        enableSorting: props.sortable,
        // Filtering
        enableFilters: props.filterable,
        manualFiltering: true,
        enableColumnFilters: true,
    });

    return (
        <div className="flex-grow flex flex-col text-base overflow-x-scroll">
            <div className="min-h-56 max-w-full">
                <table className="table-auto w-full">
                    <TableHeader
                        dataName={props.dataName}
                        headers={table.getHeaderGroups()}
                    />

                    {/* Table body */}
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr
                                key={row.id}
                                onClick={() => {
                                    if (props.onClickRow) {
                                        props.onClickRow(
                                            (row.original as any).id,
                                        );
                                    }
                                }}
                                className={`
                                    border-y border-y-ae-neutral-200 hover:bg-ae-neutral-100
                                    h-14 group/tableRow
                                    ${
                                        props.onClickRow
                                            ? "hover:cursor-pointer"
                                            : ""
                                    }
                                `}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        className="py-3 px-4 text-sm font-normal text-ae-blue-900"
                                        style={{
                                            width:
                                                cell.column.getSize() ===
                                                Number.MAX_SAFE_INTEGER
                                                    ? "auto"
                                                    : cell.column.getSize(),
                                            maxWidth: cell.column.getSize(),
                                        }}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {props.createRow && (
                            <td
                                colSpan={props.columns.length}
                                className="w-full border-y border-y-ae-neutral-200 h-14"
                            >
                                <div className="w-full flex items-center justify-center">
                                    <PrimaryButton onClick={props.createRow}>
                                        <PlusIcon className="w-4" />
                                        Add new
                                    </PrimaryButton>
                                </div>
                            </td>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Table footer area */}
            <TableFooter
                loadingData={dataQuery.isFetching}
                currentPage={table.getState().pagination.pageIndex + 1}
                pageCount={table.getPageCount()}
                itemCount={dataQuery.data?.count | 0}
                previousPage={() => table.previousPage()}
                previousPageEnabled={table.getCanPreviousPage()}
                nextPage={() => table.nextPage()}
                nextPageEnabled={table.getCanNextPage()}
                onSelectPageSize={(e) => {
                    table.setPageSize(Number(e.target.value));
                    table.setPageIndex(0);
                }}
                onPageChange={(p) => table.setPageIndex(p - 1)}
                defaultPageSize={props.defaultPageSize}
                selectedPageSize={table.getState().pagination.pageSize}
            />
        </div>
    );
};
